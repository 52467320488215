<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="10" sm="12" cols="12">
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"            
          ></v-text-field>
        </v-col>
      </v-row>
    </TheTitle>
    
    <v-card class="pa-5 full-height">
      <v-data-table
        :headers="header"
        :items="objects"
        dense
        locale="pt-BR"
        :search="search"
        :items-per-page="15"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | formatDate }}
        </template>
        
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Vizualizar">
            <v-btn icon small tile >
              <v-icon @click="dialogView1(item)">mdi mdi-file-eye-outline</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Editar" >
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>
  
    <!-- MODAL -->
    <TheDialog v-model="dialog" :title="title" :icon="pageIcon" @close-dialog="loadData" width="900px">        
      <template>
        <v-form v-model="valid" ref="form1">
          <v-divider class="my-2 mb-3"></v-divider>
          <v-row>
            <v-col md="2" sm="12" cols="12">
              <b>COD</b>
              <v-text-field
                v-model="object.id"
                outlined
                tile
                dense
                disabled
                class="my-2"
              ></v-text-field>
            </v-col>
            <v-col md="10" sm="12" cols="12">
              <b>Incidente</b>
              <v-text-field
                v-model="object.nature"
                outlined
                tile
                dense
                disabled
                class="my-2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="3" sm="12" cols="12">
              <b> Data de Comunicação</b>
              <v-text-field
                v-model="object.createdAt"
                outlined
                tile
                dense
                disabled
                class="my-2"
              ></v-text-field>
            </v-col>
            <v-col md="9" sm="12" cols="12">
              <b>E-mail</b>
              <v-text-field
                v-model="object.email"
                outlined
                tile
                dense
                disabled
                class="my-2"
              ></v-text-field>
            </v-col>
          </v-row> 
          
          <v-row>
            <v-col cols="12">
              <b>Observação</b>
              <v-textarea
                outlined
                rows="3"
                v-model="object.observation"
                class="my-2"
                auto-grow
                :rules="[requiredRule, spaceRule]"
              ></v-textarea>
            </v-col>
          </v-row>  
          <v-divider class="my-2"></v-divider>
        </v-form>
      </template>
      
      <template v-slot:actions="">
        <v-btn
          small
          depressed
          color="primary"
          class="mt-2"
          dark
          @click="resolveForm()"
        >
          <span v-if="edit"><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>  
    </TheDialog>

    <!-- VISUALIZAÇÃO -->
    <template>
      <v-dialog v-model="dialogView" max-width="1200px">
        <v-card class="pa-5">
          <v-card-title>
            <span class="headline">
              <v-icon  class="mr-2">
                mdi mdi-email-open-multiple-outline
              </v-icon>
              Informações da Comunicação de Incidente
            </span>
          </v-card-title>
          <v-divider class="my-2"></v-divider>
                    
          <v-tabs v-model="tabs" show-arrows>
            <v-tab>Comunicação</v-tab>
            <v-tab>Dados</v-tab>
            <v-tab>Justificativas</v-tab>
            <v-tab>Medidas</v-tab>
            <v-tab>Descrição</v-tab>
          </v-tabs>
          <v-divider class="my-5" />
          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <v-row>
                <v-col md="2" sm="12" cols="12">
                  <b>COD</b>
                  <v-text-field
                    v-model="objectView.id"
                    hide-details
                    dense
                    outlined
                    class="mt-2"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col md="5" sm="12" cols="12">
                  <b>E-mail</b>
                  <v-text-field
                    v-model="objectView.email"
                    hide-details
                    dense
                    outlined
                    class="mt-2"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col md="5" sm="12" cols="12">
                  <b>Data da Comunicação</b>
                  <v-text-field
                    v-model="objectView.createdAt"
                    hide-details
                    dense
                    outlined
                    class="mt-2"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <b> Observação </b>
                  <v-textarea
                    outlined
                    rows="5"
                    v-model="objectView.observation"
                    class="my-2"
                    auto-grow
                    disabled
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col md="3" sm="12" cols="12">
                  <b> Data de conhecimento *</b>
                  <v-text-field
                    v-model="objectView.incidentDate"
                    hide-details
                    dense
                    outlined
                    class="mt-2"
                    disabled
                  ></v-text-field>                                        
                </v-col>  
                <v-col md="4" sm="12" cols="12">
                  <b>Tipo de dados *</b>
                  <v-text-field
                    v-model="objectView.typeData"
                    outlined
                    tile
                    dense
                    class="my-2"
                    disabled
                  ></v-text-field>                    
                </v-col>  
                <v-col md="5" sm="12" cols="12">              
                  <b> Natureza *</b>
                  <v-text-field
                    dense
                    tile
                    outlined
                    class="my-2"
                    v-model="objectView.nature"
                    disabled
                  ></v-text-field>
                </v-col>     
              </v-row>
              <v-row>                        
                <v-col md="3" sm="12" cols="12">
                  <b>Tipo de operador *</b>
                  <v-text-field
                    v-model="objectView.typeOperating"
                    outlined
                    tile
                    dense
                    class="my-2"
                    disabled
                  ></v-text-field>                    
                </v-col> 
                <v-col md="3" sm="12" cols="12">
                  <b>Número de Titulares * </b>
                  <v-text-field
                    v-model="objectView.numberHolders"
                    outlined
                    tile
                    dense
                    class="my-2"
                    v-mask="'###'"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col md="3" sm="12" cols="12">
                  <b>Número de Titulares Crianças *</b>
                  <v-text-field
                    v-model="objectView.numberChildHolders"
                    outlined
                    tile
                    dense
                    class="my-2"
                    v-mask="'###'"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col md="3" sm="12" cols="12">
                  <b>Grau de Risco *</b>
                  <v-text-field
                    v-model="objectView.risk"
                    outlined
                    tile
                    dense
                    class="my-2"
                    disabled
                  ></v-text-field>                    
                </v-col>                                    
              </v-row>
              <v-divider class="my-2"></v-divider>
            </v-tab-item>
            <v-tab-item>
              <v-row>   
                <v-col cols="12">
                  <b> Justificativa pelo atraso na comunicação </b>
                  <v-textarea
                    outlined
                    rows="5"
                    v-model="objectView.justification"
                    class="my-2"
                    auto-grow
                    disabled
                  ></v-textarea>
                </v-col>          
              </v-row>
              <v-divider class="my-2"></v-divider>
            </v-tab-item>
            <v-tab-item>
              <v-row>   
                <v-col cols="12">
                  <b> Medidas tomadas antes *</b>
                  <v-textarea
                    outlined
                    rows="5"
                    v-model="objectView.measuresBefore"
                    class="my-2"
                    auto-grow
                    disabled
                  ></v-textarea>
                </v-col>          
              </v-row>          
              <v-divider class="my-2"></v-divider>
              <v-row>   
                <v-col cols="12">
                  <b> Medidas tomadas após *</b>
                  <v-textarea
                    outlined
                    rows="5"
                    v-model="objectView.measuresAfter"
                    class="my-2"
                    auto-grow
                    disabled
                  ></v-textarea>
                </v-col>          
              </v-row>
              <v-divider class="my-2"></v-divider>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col cols="12">
                  <b> Descrição *</b>
                  <v-textarea
                    outlined
                    rows="5"
                    v-model="objectView.description"
                    class="my-2"
                    auto-grow
                    disabled
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
            </v-tab-item>            
          </v-tabs-items>  
          <v-card-actions>
            <v-btn color="primary" @click="closeDialog()">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>  
  </v-container>
</template>
  
<script>
  import TheTitle from "@/components/TheTitle"
  import TheDialog from "@/components/TheDialog"
  import TheToolTip from "@/components/TheToolTip"
  import { mask } from "vue-the-mask"
  import { baseApiUrl, showError } from "@/global"
  import axios from "axios"
  import moment from 'moment'
  
  export default {
  
    name: "Comunicação",
    directives: { mask },
    components: {
      TheTitle,
      TheDialog,
      TheToolTip,
    },
    
    data: () => ({
      baseRoute: "communication",
      title: "Comunicação a Autoridades",
      pageIcon: "mdi mdi-email-open-multiple-outline",
  
      incident: [],
      objects: [],
      object: {},
      objectView: [],

      dialogView: false,
      dialog: false,
      search: null,
      valid: false,
      error: false,
      edit: false,
      loading: false,
      tabs: null,
  
      header: [
        {text: 'Codigo', value: 'id', width: '20%'},
        {text: 'Incidente', value: 'nature'},
        {text: 'E-mail', value: 'email'},
        {text: 'Data de Comunicação', value: 'createdAt'},
        { text: "Ações", value: "action", width: "10%" },
      ],   
    }),
  
    methods: {  

      async loadData() {
        this.$store.dispatch("setLoadingInfo", true)
        const url = `${baseApiUrl}/${this.baseRoute}`

        await this.loadIncident();

        const res = await axios.get(url)

        this.$store.dispatch("setLoadingInfo", false)

        let nature =[]
        let incidentDate = []
        let typeData = []
        let typeOperating = []
        let numberHolders = []
        let numberChildHolders = []
        let risk = []
        let justification = []
        let measuresBefore = []
        let measuresAfter = []
        let description = []

        res.data.forEach((communication) => {
          this.incident.forEach((incident) => {
            if(communication.idIncident == incident.id){
              nature.push(incident.nature)
              typeData.push(incident.typeData)
              typeOperating.push(incident.typeOperating)
              numberHolders.push(incident.numberHolders)
              numberChildHolders.push(incident.numberChildHolders)
              risk.push(incident.risk)
              justification.push(incident.justification)
              measuresBefore.push(incident.measuresBefore)
              measuresAfter.push(incident.measuresAfter)
              description.push(incident.description)
              incidentDate.push(incident.incidentDate)
            }
          })  

          this.objects = res.data                   

          this.objects.forEach((item, index) => {
            item.nature = nature[index]
            item.incidentDate = incidentDate[index]
            item.typeData = typeData[index]
            item.typeOperating = typeOperating[index]
            item.numberHolders = numberHolders[index]
            item.numberChildHolders = numberChildHolders[index]
            item.risk = risk[index]
            item.justification = justification[index]
            item.measuresBefore = measuresBefore[index]
            item.measuresAfter = measuresAfter[index]
            item.description = description[index]
          })
        })
      },

      loadIncident(){
        this.$store.dispatch("setLoadingInfo", true)
        const url = `${baseApiUrl}/incidentsNPD`
        
        return axios
          .get(url)
          .then((res => {
            this.$store.dispatch("setLoadingInfo", false)
            this.incident = res.data            
          }))
      },
   
      dialogUpdate(item) {
        this.object = item
        this.edit = true
        this.dialog = true

        this.object.createdAt = moment(this.object.createdAt).format('DD/MM/YYYY')
      },

      dialogView1(item) {
        this.objectView = item;
        this.objectView.createdAt = moment(new Date(this.objectView.createdAt)).format('DD/MM/YYYY');
        this.objectView.incidentDate = moment(new Date(this.objectView.incidentDate)).format('DD/MM/YYYY');
        this.dialogView = true;
      }, 
           
      closeDialog() {
        this.objectView = {}
        this.dialogView = false
        this.loadData()  
      },
  
      resolveForm(){
        if (!this.valid){
          this.$store.dispatch("setErrorInfo", {
            open: true,
            text: "Os campos não foram preenchidos corretamente, você pode verificar?",
            button: "Ok!",
          })
          return
        }
        if(this.edit){
          this.update()
        } else {
          this.save()
        }
      },
  
      update() {
        this.error = false
        const id = this.object.id
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        const objects = this.object 

        delete objects.id
        delete objects.nature
        delete objects.email
  
        delete objects.createdAt
        delete objects.updatedAt
        delete objects.deletedAt
        axios
          .put(url, objects)
          .then(() => this.loadData())
          .catch(showError)
          .finally(() => {
            this.dialog = false
          })
      },      
    },
  
    mounted() {
      this.loadData()
      this.loadIncident()
    },
  
  }
</script>

<style>
.full-height {
  height: 80vh;
}
</style>